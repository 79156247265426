import React from 'react';
import { IconBaseProps } from 'react-icons';

import { FaInstagram, FaSpotify, FaApple, FaSoundcloud, FaBandcamp, FaAmazon, FaTiktok} from 'react-icons/fa';
import { SiTidal, SiAmazon, SiYoutubemusic, SiApplemusic, SiPandora } from 'react-icons/si';

interface SocialMediaLink {
    name: string;
    url: string;
    icon: React.ComponentType<IconBaseProps>;
    type: string;
    fb_type: string;
  }
  
  const socialMediaLinks: SocialMediaLink[] = [
    {
      name: 'Spotify',
      url: 'https://open.spotify.com/album/5uEU9DKaSGWnCEyk99qnf2?si=SDVwgZc_R2-ee0rcm51UAQ',
      icon: FaSpotify,
      type: 'streaming',
      fb_type: 'StreamingSpotifyClicked',
    },
    {
      name: 'Apple Music',
      url: 'https://music.apple.com/us/album/muted/1697299004',
      icon: SiApplemusic,
      type: 'streaming',
      fb_type: 'StreamingAppleClicked',
    },
    {
      name: 'Tidal',
      url: 'https://tidal.com/album/304673505',
      icon: SiTidal,
      type: 'streaming',
      fb_type: 'StreamingTidalClicked',
    },
    
    {
      name: 'Amazon Music',
      url: 'https://music.amazon.com/albums/B0CBS7G9N7',
      icon: FaAmazon,
      type: 'streaming',
      fb_type: 'StreamingAmazonClicked',
    },
    {
      name: 'Bandcamp',
      url: 'https://brinary.bandcamp.com/',
      icon: FaBandcamp,
      type: 'streaming',
      fb_type: 'StreamingBandcampClicked',
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/brinarycode/',
      icon: FaInstagram,
      type: 'social',
      fb_type: 'SocialInstagramClicked',
    },
    {
      name: 'TikTok',
      url: 'https://www.tiktok.com/@brinarycode',
      icon: FaTiktok,
      type: 'social',
      fb_type: 'SociaTikTokClicked',
    },
    {
      name: 'YouTube Music',
      url: 'https://music.youtube.com/playlist?list=OLAK5uy_nj79d7azNoXuDggCJKJQHkrPhmRF5CgzA&feature=share',
      icon: SiYoutubemusic,
      type: 'streaming',
      fb_type: 'StreamingYoutubeClicked',
    },
    {
      name: 'Soundcloud',
      url: 'https://soundcloud.com/brinarycode/sets/muted',
      icon: FaSoundcloud,
      type: 'social',
      fb_type: 'SocialSoundCloudClicked',
    },
    // {
    //   name: 'Pandora',
    //   url: 'https://www.pandora.com/artist/brinary/muted/AL9djkfx27d5p7X',
    //   icon: SiPandora,
    // },
    // Add more social media links for Spotify, Apple Music, Tidal, Amazon Music, SoundCloud, etc.
  ];
  
  export default socialMediaLinks;
  