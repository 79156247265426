import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import SocialIcons from '../components/SocialIcons';
import aboutContent from '../data/aboutContent';
import '../styles/index.css';


const Home: React.FC = () => {
  return (
    <div className='App'>
      <h1 className='title'>B R I N A R Y</h1>
      <Header />
      <SocialIcons />
      <p>{aboutContent}</p>
      <Footer /> 
    </div>
  );
};

export default Home;
