import React from 'react';
import escapeImage from '../images/escape.jpg';

const Header: React.FC = () => {
  return (
    <header className='header'>
      {/* <a href="https://www.instagram.com/brinarycode/"><img src={escapeImage} alt="Escape" /></a> */}
      <img src={escapeImage} alt="Escape" />
    </header>
  );
};

export default Header;
