import React from 'react';
import socialMediaLinks from '../data/socialMediaLinks';
const SocialIcons: React.FC = () => {
  const handleButtonClick = (name: string, type: string, fbType: string) => {
    console.log(`Clicked on ${name} (${type} link)`);

    // Track events based on link type
    if (type === 'streaming') {
      fbq('trackCustom', 'StreamingClick', { content_name: name });
    } else if (type === 'social') {
      fbq('trackCustom', 'SocialClick', { content_name: name });
    }
  };

  return (
    <div>
      <h1>LISTEN</h1>
      <div className='social-icons'>
        {socialMediaLinks
          .filter((link) => link.type === 'streaming')
          .map((link, index) => (
            <a
              href={link.url}
              target='_blank'
              rel='noopener noreferrer'
              key={index}
              title={link.name}
              onClick={() =>
                handleButtonClick(link.name, link.type, link.fb_type)
              }>
              <link.icon className='icon' />
            </a>
          ))}
      </div>
      <h1>CONNECT</h1>
      <div className='social-icons'>
        {socialMediaLinks
          .filter((link) => link.type === 'social')
          .map((link, index) => (
            <a
              href={link.url}
              target='_blank'
              rel='noopener noreferrer'
              key={index}
              title={link.name}
              onClick={() =>
                handleButtonClick(link.name, link.type, link.fb_type)
              }>
              <link.icon className='icon' />
            </a>
          ))}
      </div>
    </div>
  );
};

export default SocialIcons;
